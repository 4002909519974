<template>
  <div class="container">
    <img class="bg" src="../../assets/images/index_bg.png"/>
    <van-loading type="spinner" size="22px" color="#ccc"/>
    <p class="version">北京医百科技有限公司</p>
  </div>
</template>

<script>
import { isWeChatBrowser } from "@/utils/mobile"
import { fetchMiniProgramUrlLink } from "@/api/meet"
export default {
  data() {
    return {
    }
  },

  mounted() {
    if (isWeChatBrowser()) {
      const redirect_url = this.$route.query?.redirect_url
      console.error('guide mounted', redirect_url)
      fetchMiniProgramUrlLink({ redirect_url: encodeURIComponent(redirect_url) })
      .then(
        data => {
          console.error(data)
          if (data && /https:\/\/([\w.]+\/?)\S*/.test(data)) {
            location.replace(data)
          }
        }
      ).catch(
        error => {
          console.error(`跳转小程序异常:${error}`)
        }
      )
    }
  },

  methods: {
  },
}
</script>

<style scoped lang="less">
.container {
   text-align: center;
   position: relative;
   height: 100vh;

   .bg {
      width: 256px;
      height: 256px;
      margin-top: 500px;
   }

   p.version {
      font-size: 24px;
      font-weight: 400;
      color: #999999;
      line-height: 30px;
      position: absolute;
      bottom: 72px;
      left: calc(50% - 110px);
   }
}
</style>
